/**
 * This contains the functions for building the config file so the PDFMake library can create an On Track Report PDF.
 */

// Import required libraries and utilities
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import { currentYear, getCurrentFormattedDate } from "@/functions/utils.js";

// Import custom font files for the PDF
import { Bold } from "../assets/NotoSansJP-Bold.js";
import { Regular } from "../assets/NotoSansJP-Regular.js";
import { SemiBold } from "../assets/NotoSansJP-SemiBold.js";
import { ON_TRACKPDF_SVG_ICONS as SVG_ICONS } from "./utils-svgIcons.js";

const get = {};

// Define the virtual font storage for the custom fonts
pdfMake.vfs = pdfFonts.pdfMake.vfs;
pdfMake.vfs["NotoSansJP-Bold.ttf"] = Bold;
pdfMake.vfs["NotoSansJP-Regular.ttf"] = Regular;
pdfMake.vfs["NotoSansJP-SemiBold.ttf"] = SemiBold;

// Define custom fonts for use in the PDF
pdfMake.fonts = {
  NotoSansJP: {
    normal: "NotoSansJP-Regular.ttf",
    italics: "NotoSansJP-Bold.ttf",
    bold: "NotoSansJP-SemiBold.ttf",
  },
  Roboto: {
    normal:
      "https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Regular.ttf",
    bold: "https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Medium.ttf",
  },
};

// Define font size constants for various text elements in the PDF
const FONT_SIZE = {
  normal: 11.0175,
  header: 14.625,
  subheader: 12.855,
  small: 10.95,
};

// Utility function to truncate a string if it exceeds the maximum length
const truncateString = (str, maxLength) => {
  if (str && str.length > maxLength) {
    return str.slice(0, maxLength) + "...";
  }
  return str;
};

const sections = [
  { title: "Goals" },
  { title: "Next Steps" },
  { title: "Note for Counselor" },
];
// Check if data is empty, null, or undefined
function checkData(data) {
  if (data === "" || data === null || data === undefined) {
    return "No data";
  } else {
    return data;
  }
}

// Utility function to determine the On Track status of the student
const onTrackStatus = (status) => {
  if (["on-track", "off-track", "almost on-track"].includes(status)) {
    return status.toUpperCase(); // Return status in uppercase if valid
  }
  return status == null || status === "" ? "No data" : "No data";
};

// Utility function to get the color associated with a given On Track status
const getTrackStatusColor = (status) => {
  const statusColors = {
    "on-track": "#008000", // Green color for on-track
    "off-track": "#B32A1E", // Red color for off-track
    "almost on-track": "#ffc107", // Yellow color for almost on-track
  };

  if (status && statusColors[status.toLowerCase()]) {
    return statusColors[status.toLowerCase()];
  }

  return "#707070";
};

// Function to generate table content dynamically based on the report sections
const createDynamicTableContent = (sections, courseLength, i) => {
  const tableBody = [
    sections.map((section) => ({
      stack: [
        {
          text: section.title,
          alignment: "center",
          fontSize: FONT_SIZE.header,
          bold: true,
        },
        {
          canvas: [
            {
              type: "rect", // Rectangle shape for the section box
              x: 0,
              y: 0,
              w: 178,
              h: courseLength < 12 ? 101 : 50,
              r: 8,
              color: "#ffffff", // White background
              lineWidth: 1,
              strokeColor: "#000000", // Black border
              lineColor: "black",
              fillColor: null, // No fill color inside the rectangle
            },
          ],
          margin: [0, 5, 0, 0],
        },
      ],
      alignment: "center",
    })),
  ];

  return {
    alignment: "center",
    table: {
      widths: ["*", "*", "*"], // Define equal column widths
      body: tableBody,
    },
    layout: "noBorders",
    pageBreak: i ? "after" : undefined,
  };
};

const markingPeriods = ["Q1", "Q2", "Q3", "Q4", "S1", "S2", "Y1"];

// change formate of currentYear '2425' -> '2024-25'
const yearRange = `20${String(currentYear)?.slice(0, 2)}-${String(
  currentYear
)?.slice(2)}`;

// Function to get the list of courses for a given student
get.Courses = ({ courses }) => {
  const courseKeys = courses ? Object.keys(courses) : []; // Get all course keys

  const myCourses = courseKeys
    .map((key) => courses[key]) // Map each course key to its course data
    .filter((course) => course !== undefined) // Filter out undefined courses
    .filter((course) => course.schoolYear == currentYear) // Filter courses for the current year
    .sort((a, b) => (a.period > b.period ? 1 : -1)) // Sort courses by period
    .map((course, index) => {
      const fillColor = index % 2 !== 0 ? "#f2f2f2" : "#ffffff"; // Alternate row colors

      return [
        { text: truncateString(course.courseTitle, 20), fillColor }, // Truncated course title
        { text: truncateString(course.teacher.lastName, 10), fillColor }, // Truncated teacher's last name
        { text: course.creditHours, fillColor, alignment: "center" }, // Course credit hours
        ...markingPeriods.map((period) => {
          if (!course.letterGrade[period])
            return { text: "", fillColor, alignment: "center" }; // No grade for the period
          return {
            text: course.percentageGrade[period]?.toFixed(), // Percentage grade for the period
            fillColor,
            alignment: "center",
          };
        }),
        {
          text: course.currentAbsences?.toString(),
          fillColor,
          alignment: "center",
        },
        {
          text: course.currentTardies?.toString(),
          fillColor,
          alignment: "center",
        },
      ];
    });

  // Define Table header Data
  const headerRow = [
    {
      text: "Courses",
      bold: true,
      fontSize: FONT_SIZE.subheader,
      alignment: "left",
    },
    {
      text: "Teacher",
      bold: true,
      fontSize: FONT_SIZE.subheader,
      alignment: "left",
    },
    {
      text: "Credits",
      bold: true,
      fontSize: FONT_SIZE.subheader,
      alignment: "center",
    },
    {
      text: "Q1",
      bold: true,
      fontSize: FONT_SIZE.subheader,
      alignment: "center",
    },
    {
      text: "Q2",
      bold: true,
      fontSize: FONT_SIZE.subheader,
      alignment: "center",
    },
    {
      text: "Q3",
      bold: true,
      fontSize: FONT_SIZE.subheader,
      alignment: "center",
    },
    {
      text: "Q4",
      bold: true,
      fontSize: FONT_SIZE.subheader,
      alignment: "center",
    },
    {
      text: "S1",
      bold: true,
      fontSize: FONT_SIZE.subheader,
      alignment: "center",
    },
    {
      text: "S2",
      bold: true,
      fontSize: FONT_SIZE.subheader,
      alignment: "center",
    },
    {
      text: "Y1",
      bold: true,
      fontSize: FONT_SIZE.subheader,
      alignment: "center",
    },
    {
      text: "Abs",
      bold: true,
      fontSize: FONT_SIZE.subheader,
      alignment: "center",
    },
    {
      text: "Tdy",
      bold: true,
      fontSize: FONT_SIZE.subheader,
      alignment: "center",
    },
  ];

  return {
    style: "coursesTable",
    fontSize: FONT_SIZE.normal,
    lineHeight: 0.85,
    color: "#333333",
    margin: [0, 5, 0, 0],
    layout: "noBorders",
    table: {
      headerRows: 1, // First row is the header
      widths: [
        "27%",
        "16%",
        "10%",
        "5%",
        "5%",
        "5%",
        "5%",
        "5%",
        "5%",
        "5%",
        "5%",
        "5%",
      ], // Define column widths
      alignment: "center",
      body: [headerRow, ...myCourses], // Combine header and courses data
    },
  };
};

// Function to generate the PDF based on the student data
export const generatePDF = async ({
  student,
  areasToShow,
  schoolName,
  MultiPageOnTrack,
}) => {
  // Function to generate SVG icons for the On Track status
  const generateSVGIcon = (isOnTrack, onTrackSVG, offTrackSVG) => {
    if (isOnTrack === null) {
      return "";
    }
    return {
      svg: isOnTrack ? onTrackSVG : offTrackSVG,
      margin: [3, 3, 0, 0],
    };
  };

  // Function to create a column of content for the report
  const createColumnContent = (label, value) => {
    return {
      columnGap: "25",
      width: "25%",
      stack: [
        {
          text: !["", null].includes(value) ? value : "-", // Display value or "-" if no value
          fontSize: 15,
          color: "#707070",
          italics: true,
        },
        { text: label, fontSize: 11, color: "#1a1c1e", margin: [5, 0] },
      ],
    };
  };

  // Function to create the  Track status label in the PDF
  const createOnTrackLabel = (
    isOnTrack,
    label,
    hideLabel = false,
    options = {}
  ) => {
    const { fontSize = FONT_SIZE.header } = options;
    if (hideLabel) {
      return {
        text: [{ text: `${label}`, fontSize, bold: true }],
        width: "auto",
      };
    }
    return {
      text: [
        { text: `${label}: `, fontSize, bold: true },
        {
          text: isOnTrack ? "ON-TRACK" : "OFF-TRACK", // Display "ON-TRACK" or "OFF-TRACK"
          fontSize,
          italics: true,
          color: isOnTrack ? "#008000" : "#B32A1E", // Green or Red color based on the status
        },
      ],
      width: "auto",
    };
  };

  // Function to create the student information section in the report
  const getFieldValue = (value, defaultValue = "No data") => {
    return !value || value === "" || value === null ? defaultValue : value;
  };

  function createStudentInfo(
    {
      firstName,
      lastName,
      currentGradeLevel,
      cohort,
      studentDistrictId,
      counselorName,
    },
    svgimg,
    yearRange,
    schoolName
  ) {
    // Destructure student data for easier access

    // Student name
    const studentName = `${getFieldValue(firstName)} ${getFieldValue(
      lastName
    )}`;
    const studentGrade = `Grade: ${getFieldValue(currentGradeLevel)}`;
    const studentCohort = `Cohort: ${getFieldValue(cohort)}`;
    const studentId = `Student ID: ${getFieldValue(studentDistrictId)}`;
    const counselor = `Counselor: ${getFieldValue(counselorName)}`;

    // Create the student information structure
    return {
      columns: [
        {
          svg: svgimg,
          width: 90,
          margin: [11, 18, 0, 0], // RISE NETWORK logo
        },
        {
          stack: [
            {
              text: [
                { text: studentName, bold: true, fontSize: 19.5 },
                { text: " On-Track Report \n", fontSize: 18 },
                {
                  text: `${yearRange} ${schoolName}`, //school Name  & Year
                  fontSize: 14.62,
                  bold: true,
                  color: "#333333",
                },
              ],
              margin: [0, 0, 0, 8], // Add spacing below the header text
            },
            {
              columns: [
                [
                  { text: studentGrade },
                  { text: studentCohort },
                  { text: `Data From: ${getCurrentFormattedDate()}` },
                ],
                [
                  { text: studentId },
                  { text: counselor },
                  { text: "Conferencer: ____________" }, // Placeholder for conferencer
                ],
              ],
              alignment: "left",
              color: "#1a1c1e",
              fontSize: 13,
              columnGap: 57, // Gap between the two columns
            },
          ],
          margin: [53, 0, 0, 0], // Adjusting margin for alignment
        },
      ],
      color: "#1A1C1E", // Text color for the entire section
    };
  }

  // Function to create the row showing the On Track status in the report title
  function createOnTrackTitleRow({ statusText, statusColor, statusIcon }) {
    return {
      columns: [
        {
          text: [
            {
              text: "Courses and Grades: ",
              fontSize: FONT_SIZE.header,
              bold: true,
            },
            {
              text: statusText,
              fontSize: FONT_SIZE.header,
              italics: true,
              color: statusColor,
            },
          ],
          width: "auto",
        },
        statusIcon && statusText !== "No data"
          ? {
              svg: statusIcon,
              width: 17,
              margin: [5, 2, 5, 0],
            }
          : { text: "", width: 17, margin: [5, 2, 5, 0] },
      ],
      margin: [0, 9, 0, 0],
    };
  }

  const createAttendanceSection = ({
    ADAValue,
    hasADA,
    onTrack,
    quarterlyAbsences,
  }) => {
    return {
      columns: [
        {
          width: "35%",
          stack: [
            {
              columns: [
                createOnTrackLabel(
                  onTrack, // Determine if the student is "on-track"
                  "Attendance", // Label text
                  !hasADA // Hide label if ADA data is missing
                ),
                hasADA
                  ? generateSVGIcon(
                      onTrack,
                      SVG_ICONS.ON_TRACK_SVG,
                      SVG_ICONS.OFF_TRACK_SVG
                    )
                  : null,
              ],
              width: "auto",
            },
            {
              text: "On-track means having above 90% Average Daily Attendance.",
              fontSize: 11,
              margin: [0, 4, 0, 0],
              color: "#333333",
            },
          ],
        },
        {
          text: "Absences", // Label for absences column
          fontSize: 11,
          alignment: "center",
          color: "#1a1c1e",
          width: "13%",
          bold: true,
          margin: [0, 22, 0, 0],
        },
        {
          stack: [
            {
              columns: quarterlyAbsences, // Add quarterly absence columns dynamically
            },
          ],
          width: "35%",
          alignment: "center",
          layout: "noBorders",
          margin: [0, 14, 0, 0],
        },
        {
          canvas: [
            {
              type: "line", // Line separator between columns
              x1: 10,
              y1: 0,
              x2: 10,
              y2: 40,
              lineWidth: 1,
              lineColor: "#707070",
              dash: { length: 5, space: 1 }, // Dashed line style
            },
          ],
          width: "5%",
          alignment: "center",
          margin: [0, 15, 0, 0],
        },
        {
          stack: [
            {
              text: ADAValue === "No data" ? ADAValue : ADAValue + " %", // Show the ADA percentage (or "No data" if missing)
              fontSize: 15,
              italics: true,
              color: "#707070",
            },
            { text: "Average", fontSize: 12 },
          ],
          alignment: "center",
          width: "12%",
          margin: [0, 15, 0, 0],
        },
      ],
      margin: [0, 5, 0, 0],
    };
  };

  // Behavior section
  const createBehaviorSection = ({
    suspensions,
    hasSuspensionData,
    totalSuspensions,
    quarterlySuspensions,
  }) => {
    const quarterlyColumns = quarterlySuspensions.map((suspension, i) =>
      createColumnContent(`Q${i + 1}`, suspension)
    );
    return {
      columns: [
        {
          width: "35%",
          stack: [
            {
              columns: [
                createOnTrackLabel(
                  suspensions === 0, // On-track if no suspensions
                  "Behavior", // Label text
                  !hasSuspensionData // Hide label if no suspension data
                ),
                generateSVGIcon(
                  hasSuspensionData && suspensions === 0,
                  SVG_ICONS.ON_TRACK_SVG,
                  SVG_ICONS.OFF_TRACK_SVG
                ),
              ],
              width: "auto",
            },
            {
              text:
                "On-track means no suspensions in \n the current school year.",
              fontSize: 11,
              margin: [0, 4, 0, 0],
              color: "#333333",
            },
          ],
        },
        {
          text: "Suspensions", // Label for suspensions column
          fontSize: 11,
          alignment: "center",
          color: "#1a1c1e",
          width: "13%",
          bold: true,
          margin: [0, 22, 0, 0],
        },
        {
          stack: [
            {
              columns: quarterlyColumns, // Add quarterly suspension columns
            },
          ],
          width: "35%",
          alignment: "center",
          layout: "noBorders",
          margin: [0, 14, 0, 0],
        },
        {
          canvas: [
            {
              type: "line", // Line separator between columns
              x1: 10,
              y1: 0,
              x2: 10,
              y2: 40,
              lineWidth: 1,
              lineColor: "#707070",
              dash: { length: 5, space: 1 }, // Dashed line style
            },
          ],
          width: "5%",
          alignment: "center",
          margin: [0, 15, 0, 0],
        },
        {
          stack: [
            {
              text: totalSuspensions, // Total suspensions
              fontSize: 15,
              italics: true,
              color: "#707070",
            },
            { text: "Total", fontSize: 12 },
          ],
          alignment: "center",
          width: "12%",
          margin: [0, 15, 0, 0],
        },
      ],
      margin: [0, 5, 0, 0],
    };
  };

  // student's postsecondary plan, GPA, applications submitted, FAFSA status
  const createPostSecondarySection = ({
    GPA,
    collegeAppsSubmitted,
    FAFSAStatus,
  }) => {
    const plans = student?.plans?.[0]?.value || "No Plan";
    const FAFSACompleted = FAFSAStatus
      ? FAFSAStatus === "completed"
        ? "Yes"
        : "No"
      : "No";

    return {
      table: {
        widths: ["*", "*"],
        body: [
          [
            {
              stack: [
                {
                  text: "Postsecondary Success", // Label
                  style: "header",
                  fontSize: 15,
                  bold: true,
                  margin: [0, 0, 0, 3],
                },
                {
                  text: "Postsecondary Plan", // Label
                  style: "subheader",
                  bold: true,
                },
                {
                  text: plans, // Display the student's plan
                  style: "subheader",
                  color: "#1a1c1e",
                },
              ],
              fontSize: FONT_SIZE.small,
            },
            {
              table: {
                widths: ["*", "*", "*"], // Three columns for GPA, applications, FAFSA
                body: [
                  [
                    {
                      stack: [
                        {
                          text: GPA, // GPA display
                          style: "gpa",
                          italics: true,
                          alignment: "center",
                          fontSize: 15,
                          color: "#707070",
                        },
                        {
                          text: "Cumulative \n GPA",
                          style: "label",
                          alignment: "center",
                        },
                      ],
                      alignment: "center",
                    },
                    {
                      stack: [
                        {
                          text: collegeAppsSubmitted, // College applications submitted
                          style: "applications",
                          alignment: "center",
                          fontSize: 15,
                          italics: true,
                          color: "#707070",
                        },
                        {
                          text: "Applications Submitted",
                          style: "label",
                          alignment: "center",
                        },
                      ],
                      alignment: "center",
                    },
                    {
                      stack: [
                        {
                          text: FAFSACompleted, // FAFSA status
                          style: "response",
                          alignment: "center",
                          fontSize: 15,
                          italics: true,
                          color: "#707070",
                        },
                        { text: "FAFSA", style: "label", alignment: "center" },
                        {
                          text: "Completed",
                          style: "label",
                          alignment: "center",
                        },
                      ],
                      alignment: "center",
                    },
                  ],
                ],
              },
              margin: [13, 0, 0, 0],
              fontSize: FONT_SIZE.small,
              layout: "noBorders",
            },
          ],
        ],
      },
      margin: [0, 5, 0, 0],
      layout: "noBorders",
    };
  };

  // student's intended major and GPA-based track status
  const createMajorSection = (student) => {
    const intendedMajor = student.intendedMajor || "No data";
    const GPA = student?.GPA;
    const isGPAValid = !["", null].includes(GPA);
    const GPAStatus = isGPAValid ? (GPA < 3 ? "OFF-TRACK" : "ON-TRACK") : "";
    const GPAColor = isGPAValid ? (GPA < 3 ? "#B32A1E" : "#008000") : "";
    const GPASvg = isGPAValid
      ? GPA < 3
        ? SVG_ICONS.OFF_TRACK_SVG
        : SVG_ICONS.ON_TRACK_SVG
      : "";
    return {
      table: {
        widths: ["*", "*"],
        body: [
          [
            {
              stack: [
                { text: "Intended Major", style: "header", bold: true },
                { text: intendedMajor, color: "#333333" },
              ],
              margin: [0, 0, 10, 0],
              fontSize: FONT_SIZE.small,
            },
            {
              stack: [
                {
                  columns: [
                    {
                      text: [
                        { text: "Bs or Better: ", style: "header", bold: true },
                        GPAStatus && {
                          text: `${GPAStatus}`,
                          italics: true,
                          color: GPAColor,
                        },
                      ],
                      width: "auto",
                    },
                    GPAStatus && {
                      svg: GPASvg,
                      width: 14,
                      margin: [5, 1.3, 5, 0],
                    },
                  ],
                },
                {
                  text:
                    "On-track means having a cumulative GPA of \n 3.0 or higher.",
                  color: "#333333",
                },
              ],
              margin: [25, 0, 0, 0],
              fontSize: FONT_SIZE.small,
            },
          ],
        ],
      },
      margin: [0, 3, 0, 3],
      layout: "noBorders",
    };
  };
  try {
    // Main function that generates the complete PDF document
    const studentDataArray = Array.isArray(student) ? student : [student]; // Wrap single student into an array

    const result = studentDataArray.map((student) => {
      try {
        const {
          firstName,
          lastName,
          currentGradeLevel,
          cohort,
          studentDistrictId,
          counselorName,
        } = student;

        // Cache commonly accessed properties to avoid repeated property lookups
        const intendedMajor = student?.intendedMajor?.[0]?.value || "No data"; // Default to "No data" if missing
        const GPA = student?.GPA ?? "No data"; // Default to "No data" if GPA is missing
        const collegeAppsSubmitted = checkData(student?._collegeAppsSubmitted); // Default to "No data" if no applications submitted
        const FAFSAStatus = student?.FAFSAStatus?.[0]?.value || "No data"; // Default to "No data" if FAFSA status is missing
        const attendance = student?.attendance || "No data"; // Default to "No data" if attendance is missing
        const behavior = student?.behavior || "No data"; // Default to "No data" if behavior is missing
        const postsecondaryPlan = student?.postsecondaryPlan || "No data"; // Default to "No data" if postsecondary plan is missing

        // Handle suspensions and quarter-based suspensions in a similar way
        const suspensions = student?.suspensions ?? 0; // Default to 0 if no suspensions data
        const hasSuspensionData = !["", null].includes(student?.suspensions); // Check if suspension data exists
        const totalSuspensions = suspensions.toFixed(); // Format the total suspensions
        const quarterlySuspensions = Array.from(
          { length: 4 },
          (_, i) => student?.[`Q${i + 1}Suspensions`] || 0 // Default to 0 if no suspension data for quarter
        );

        // Handle ADA (Average Daily Attendance)
        const ADA = student?.ADA ?? null; // Get ADA (Average Daily Attendance), or null if missing
        const hasADA = !["", null].includes(ADA); // Check if ADA data exists
        const ADAValue = hasADA ? ADA.toFixed(1) : "No data"; // Format ADA value if it exists, else "No data"
        const onTrack = ADA >= 90; // Check if ADA is 90 or above for "on-track" status

        // Create quarterly absences dynamically
        const quarterlyAbsences = Array.from(
          { length: 4 },
          (_, i) =>
            createColumnContent(
              `Q${i + 1}`,
              student?.[`Q${i + 1}Absences`] ?? 0
            ) // Default to 0 if no absence data for quarter
        );

        const courses = student?.courses ?? {}; // Get the courses data (default to empty object if not present)

        // Handle onTrackStatus for the student
        const onTrack_Status = student?.onTrackStatus; // Cache onTrackStatus for efficiency
        const statusText = onTrack_Status
          ? onTrackStatus(onTrack_Status)
          : "No data"; // Default text if no status
        const statusColor = getTrackStatusColor(onTrack_Status); // Get status color
        const statusIcon = onTrackStatus
          ? onTrack_Status === "on-track"
            ? SVG_ICONS.ON_TRACK_SVG
            : onTrack_Status === "almost on-track"
            ? SVG_ICONS.ALMOST_ON_TRACK_SVG
            : SVG_ICONS.OFF_TRACK_SVG
          : ""; // Map status to SVG icon

        // Return the extracted data in a clean structure
        return {
          suspensions,
          courses,
          intendedMajor,
          GPA,
          collegeAppsSubmitted,
          FAFSAStatus,
          attendance,
          behavior,
          postsecondaryPlan,
          totalSuspensions,
          quarterlySuspensions,
          hasSuspensionData,
          ADAValue, // Include the formatted ADA value
          onTrack, // Include the onTrack status for ADA
          quarterlyAbsences, // Include the quarterly absences data
          hasADA, // Include onTrackStatus and related data
          statusText, // The status text (e.g., "On Track", "Almost On Track")
          statusColor, // The color associated with the track status
          statusIcon, // The SVG icon representing the student's track status
          firstName,
          lastName,
          currentGradeLevel,
          cohort,
          studentDistrictId,
          counselorName,
        };
      } catch (error) {
        const { firstName, lastName, studentDistrictId } = student;
        // If an error occurs, return an error object
        return {
          error: true,
          firstName,
          lastName,
          studentDistrictId,
        };
      }
    });

    const _content = result.map((studentData, i) => {
      const courseKeys = studentData.courses
        ? Object.keys(studentData.courses)
        : [];
      if (studentData?.error) {
        // Create an error message for the student
        return {
          text: `The On-track Report for ${studentData?.firstName} ${studentData?.lastName}, ${studentData?.studentDistrictId} failed to generate.`,
          fontSize: 14,
          margin: [0, 20, 0, 0],
          alignment: "center",
          pageBreak: i < result.length - 1 ? "after" : "",
        };
      }
      return [
        createStudentInfo(
          studentData,
          SVG_ICONS.HEADER_IMG_SVG,
          yearRange,
          schoolName
        ), // Include studentData Information
        createOnTrackTitleRow(studentData), //studentData Courses and Grades TRACK status
        get.Courses(studentData), // Include courses table
        createAttendanceSection(studentData), // Include attendance section
        createBehaviorSection(studentData), // Include behavior section
        createPostSecondarySection(studentData), // Include postsecondary section
        createMajorSection(studentData), // Include major section
        createDynamicTableContent(
          sections,
          courseKeys.length,
          i < result.length - 1
        ),
      ];
    });

    const documentDefinition = {
      compress: true,
      content: _content,
      defaultStyle: {
        font: "NotoSansJP",
      },
      pageSize: {
        width: 612, // Page width
        height: 790, // Page height
      },
      pageMargins: [23, 23, 18, 5], // Margins of the document
    };

    const now = new Date();
    const formattedDate =
      now.getFullYear() +
      String(now.getMonth() + 1).padStart(2, "0") +
      String(now.getDate()).padStart(2, "0") +
      String(now.getHours()).padStart(2, "0") +
      String(now.getMinutes()).padStart(2, "0") +
      String(now.getSeconds()).padStart(2, "0");

    let fileName;
    if (result.some((studentData) => studentData.error)) {
      // If there are any errors, create an error report
      fileName = `On-track_reports_errors_${formattedDate}.pdf`;
    } else if (MultiPageOnTrack) {
      fileName = `On-track_reports_${formattedDate}.pdf`;
    } else {
      fileName = `${result[0].lastName}_${result[0].firstName}_${result[0].studentDistrictId}.pdf`;
    }
    localStorage.setItem("isLoading", true);
    setTimeout(async () => {
      const downloadPdf = new Promise((resolve) => {
        pdfMake.createPdf(documentDefinition).download(fileName, () => {
          localStorage.setItem("isLoading", false);
        });
        resolve();
      });
      await downloadPdf;
    }, 0);
  } catch (error) {
    console.error("Error generating PDF:", error);
    localStorage.setItem("isLoading", false); 
  }
};
